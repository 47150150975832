import "./App.css";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Signup from "./Log-Reg/sign-up.js";
import Login from "./Log-Reg/login.js";
import Main from "./Main/main.js";
import LRHeader from "./Log-Reg/lr-header.js";
import React, { useEffect, useState } from "react";
import NotFoundPage from './NotFoundPage.js';

function App() {

  const [isAuthenticated, setIsAuth] = useState(!!localStorage.getItem("token"))
  // const isAuthenticated = true
  // console.log(isAuthenticated)
  // const isAuthenticated = true
  return (
    <>
      <Router>
        <Routes>
          <Route
            path="/signup"
            element={
              <>
                <LRHeader />
                <Signup />
              </>
            }
          />
          <Route
            path="/login"
            element={
              <>
                <LRHeader />
                <Login setIsAuth={setIsAuth} />
              </>
            }
          />
          <Route
            path="/"
            element={
              isAuthenticated
                ?
                <Navigate to="/home" replace />
                :
                <Navigate to="/login" replace />
            }
          />
          <Route
            path="/home"
            element=
            {
              isAuthenticated
                ?
                <Main />
                :
                <Navigate to="/login" replace />
            }
          />
          <Route path="/404" element={<NotFoundPage />} />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
