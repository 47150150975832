import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  status, login, get_username, register, register_admin, upload, artist_report,
  label_report, all_report, info_quarter, user_reports, download_report, delete_reports,
  getRequest, postRequest, deleteRequest, postFormDataRequest, get_artist_names, get_label_names, get_track_names,
  mainlink
} from '../../api';
import { Line } from 'rc-progress';
import { SpinnerCircularSplit } from 'spinners-react';

function Workspace() {
  let navigate = useNavigate();
  let location = useLocation();
  const token = localStorage.getItem('token');
  const [isUploadQuarter, setIsUploadQuarter] = useState(false);
  const [selectedButton, setSelectedButton] = useState(null);
  const [year, setYear] = useState('2024');
  const [fileNameText, setFileNameText] = useState('Загрузите файл');
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  const [lastUploads, setLastUploads] = useState([]);
  const [userReports, setUserReports] = useState([]);
  const [isLeftSideActive, setIsLeftSideActive] = useState(true);
  const [selectedType, setSelectedType] = useState('');
  const [selectedFile, setSelectedFile] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedQuarter, setSelectedQuarter] = useState('');
  const [artLab, setArtLab] = useState('');
  const [stavka, setStavka] = useState('');
  const [percentLoaderVisible, setPercentLoaderVisible] = useState(true)
  const [count, setCount] = useState(0)
  const [finalFileName, setFinalFileName] = useState('')
  const [trackRates, setTrackRates] = useState([{ track: '', rate: '' }]);
  const [trackNames, setTrackNames] = useState([]);
  const [isTelegram, setIsTelegram] = useState(false);
  const [telegramChatId, setTelegramChatId] = useState(null);

  useEffect(() => {
    fetchAndProcessInfoQuarter(token);
    fetchAndProcessUserReports(token);

    // Проверяем, есть ли в URL параметры Telegram
    const searchParams = new URLSearchParams(location.search);
    const tgChatId = searchParams.get('tgChatId');
    if (tgChatId) {
      setIsTelegram(true);
      setTelegramChatId(tgChatId);
    }
  }, [token, location]);

  async function fetchAndProcessInfoQuarter(token) {
    const data = await getRequest(info_quarter, token);
    if (data) {
      setLastUploads(data);
    }
  }

  async function fetchAndProcessUserReports(token) {
    const data = await getRequest(user_reports, token);
    if (data) {
      setUserReports(data.files);
    }
  }

  const handleChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    setYear(value);
  };

  const handleBlur = () => {
    let numYear = parseInt(year, 10);
    if (isNaN(numYear)) {
      numYear = 2024;
    } else if (numYear < 2000) {
      numYear = 2000;
    } else if (numYear > 2024) {
      numYear = 2024;
    }
    setYear(numYear.toString());
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setFileNameText(event.target.files[0].name);
  };

  const handleDivClick = () => {
    fileInputRef.current.click();
  };
  const [spinnerActive, setSpinnerActive] = useState(false)

  async function checkForLoading(fileName, myFunction, moreFunc = null) {
    let response = false;
    while (!response) {
      const check = await getRequest(`/check_status/${fileName}`, token);
      response = check.status === true;
      myFunction(check.percent)
      if (!response) {
        await new Promise(resolve => setTimeout(resolve, 3000));
      }
    }
    if (moreFunc) {
      moreFunc(token)
    }
  }

  useEffect(() => {
    if (localStorage.getItem('activeLoading')) {
      checkForLoading(localStorage.getItem('activeLoading'), setPercLoad)
    }
  }, [])

  function handleSetSelected(filename, yearData, quarter) {
    setSelectedFile(filename);
    setSelectedYear(yearData.year);
    setSelectedQuarter(quarter.quarter_number);
  }

  async function handleClickUpload() {
    setSender(true)
    setSpinnerActive(true);
    setIsLeftSideActive(true);
    if (file && year && selectedButton) {
      setErr('');
      const data = await uploadFile(file, year, selectedButton, token);

      if (data) {
        if (data.detail) {
          setErr(data.detail)
          return
        }
        setIsLeftSideActive(false);
        setSpinnerActive(false);
        setIsUploadQuarter(false);
        setSender(false)
        localStorage.setItem('activeLoading', '')
        setFileNameText('Выберите файл')
        fetchAndProcessInfoQuarter(token)
      }
    } else {
      setSender(false)
      setSpinnerActive(false);
      setErr('Выберите файл, год и квартал.');
    }
  }

  async function uploadFile(file, year, quarter, token) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_data', JSON.stringify({ year: year, quarter: quarter }));

    try {
      const data = await postFormDataRequest(upload, formData, token);
      if (data) {
        if (data.detail) {
          return data
        }
      } else {
        return data
      }
      setIsUploadQuarter(false)
      let response = false;
      localStorage.setItem('activeLoading', data.file_name)
      while (!response) {
        const check = await getRequest(`/check_status/${data.file_name}`, token);
        response = check.status === true;
        setPercLoad(check.percent)
        if (!response) {
          await new Promise(resolve => setTimeout(resolve, 3000));
        }
      }
      handleSetSelected(file.name, { year: year }, { quarter_number: selectedButton })

      return data;
    } catch (error) {
      return null;
    }
  }

  useEffect(() => {
    if (count <= 0) {
      setPercentLoaderVisible(false)
    } else if (count === 100) {
      setPercentLoaderVisible(false)
    } else {
      setPercentLoaderVisible(true)
    }
  }, [count])

  async function sendSettings(by, artLab, stavka, tracks_rate) {
    let url;
    let body;

    if (!stavka) {
      stavka = '100'
    }

    if (by === 'artist') {
      url = artist_report;
      if (!artLab) {
        setMainErr('Введите название')
        return
      }
      body = {
        artist_name: artLab,
        "sum_rate": stavka,
        "file_name": selectedFile,
        "tracks_rate": tracks_rate
      }
    } else if (by === 'label') {
      url = label_report;
      if (!artLab) {
        setMainErr('Введите название')
        return
      }
      body = {
        label_name: artLab,
        "sum_rate": stavka,
        "file_name": selectedFile,
        "tracks_rate": tracks_rate
      }
    } else if (by === 'all') {
      url = all_report;
      body = {
        "sum_rate": stavka,
        "file_name": selectedFile,
        "tracks_rate": tracks_rate
      };
    }

    try {
      handleDeleteSelectedSheet()
      setSelectedType(0)
      const data = await postRequest(url, body, token)
      checkForLoading(data.filename, setCount, fetchAndProcessUserReports)
      setCount(0)

      return data;
    } catch (error) {
      return null;
    }
  }

  const handleArtLabChange = (e) => {
    setArtLab(e.target.value);
  };

  const handleStavkaChange = (e) => {
    setStavka(e.target.value);
  };

  function SelectedChild() {
    const [trackRates, setTrackRates] = useState([{ track: '', rate: '' }]);

    const handleSubmit = (e) => {
      e.preventDefault();
      const formData = new FormData(e.target);
      const artLab = formData.get('artLab') || '';
      const stavka = formData.get('stavka') || '';

      const tracks_rate = {};
      trackRates.forEach(tr => {
        if (tr.track && tr.rate) {
          tracks_rate[tr.track] = parseInt(tr.rate, 10);
        }
      });

      if (selectedType === 1) {
        sendSettings('artist', artLab, stavka, tracks_rate);
      } else if (selectedType === 2) {
        sendSettings('label', artLab, stavka, tracks_rate);
      } else if (selectedType === 3) {
        sendSettings('all', '', stavka, tracks_rate);
      }
    };

    const addTrackRate = () => {
      setTrackRates([...trackRates, { track: '', rate: '' }]);
    };

    const removeTrackRate = (index) => {
      const newTrackRates = trackRates.filter((_, i) => i !== index);
      setTrackRates(newTrackRates);
    };

    const handleTrackRateChange = (index, field, value) => {
      const newTrackRates = [...trackRates];
      newTrackRates[index][field] = value;
      setTrackRates(newTrackRates);
    };

    if (selectedType === 1 || selectedType === 2) {
      console.log(trackNames);
      return (
        <form className="inputs" onSubmit={handleSubmit}>
          <datalist id="names">
            {artLabNames.map((oneName, index) => (
              <option key={index} value={oneName} />
            ))}
          </datalist>
          <datalist id="trackNames">
            {trackNames.map((trackName, index) => (
              <option key={index} value={trackName} />
            ))}
          </datalist>
          <input
            list="names"
            className="basic-input"
            name="artLab"
            placeholder={selectedType === 1 ? 'Артист' : 'Лейбл'}
            type="text"
          />
          <input
            className="basic-input"
            name="stavka"
            placeholder="Ставка"
            type="text"
          />
          <button type="button" className="btn" onClick={addTrackRate}>
            Добавить ставку по произведению
          </button>
          {trackRates.map((trackRate, index) => (
            <div key={index} className="track-rate-inputs">
              <input
                className="basic-input"
                placeholder="Произведение"
                list="trackNames"
                value={trackRate.track}
                onChange={(e) => handleTrackRateChange(index, 'track', e.target.value)}
              />
              <input
                className="basic-input"
                placeholder="Ставка произведения"
                value={trackRate.rate}
                onChange={(e) => handleTrackRateChange(index, 'rate', e.target.value)}
              />
              <button type="button" className="btn" onClick={() => removeTrackRate(index)}>
                ✕
              </button>
            </div>
          ))}
          <button className="btn" type="submit">Отправить</button>
        </form>
      );
    } else if (selectedType === 3) {
      return (
        <form className="inputs" onSubmit={handleSubmit}>
          <input
            className="basic-input"
            name="stavka"
            placeholder="Ставка"
            type="text"
          />
          <button type="button" className="btn" onClick={addTrackRate}>
            Добавить ставку по произведению
          </button>
          {trackRates.map((trackRate, index) => (
            <div key={index} className="track-rate-inputs">
              <input
                className="basic-input"
                placeholder="Произведение"
                value={trackRate.track}
                onChange={(e) => handleTrackRateChange(index, 'track', e.target.value)}
              />
              <input
                className="basic-input"
                placeholder="Ставка произведения"
                value={trackRate.rate}
                onChange={(e) => handleTrackRateChange(index, 'rate', e.target.value)}
              />
              <button type="button" className="btn" onClick={() => removeTrackRate(index)}>
                ✕
              </button>
            </div>
          ))}
          <button className="btn" type="submit">Отправить</button>
        </form>
      );
    }
  }

  useEffect(() => {
    if (selectedFile) {
      setIsLeftSideActive(false);
    } else {
      setIsLeftSideActive(true);
    }
  }, [selectedFile]);

  function handleDeleteSelectedSheet() {
    setSelectedFile('');
    setSelectedQuarter('');
    setSelectedYear('');
  }
  const [artLabNames, setArtLabNames] = useState([])
  const [artLabInfo, setArtLabInfo] = useState('')
  const [artLabAddInfo, setArtLabAddInfo] = useState('')

  async function fetchTrackNames(token, fileName) {
    const url = get_track_names(fileName);
    fetch(mainlink + url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/octet-stream',
      },
    })
      .then(resp => {
        if (resp.ok) {
          return resp.json();
        } else {
          throw new Error('Ошибка при получении данных');
        }
      })
      .then(data => {
        console.log("Треки получены:", data.track_names);
        setTrackNames(data.track_names);
      })
      .catch(error => {
        console.error('Ошибка:', error);
      });
  }

  async function get_names(token, report, by) {
    let url
    setArtLabInfo('Имена артистов и лейблов из вашего файла скоро появятся')
    setArtLabAddInfo('В следующем отчете с этим файлом они будут загружены моментально')
    if (by === 'label') {
      url = get_label_names(report);
    } else {
      url = get_artist_names(report);
    }

    fetch(mainlink + url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/octet-stream',
      },
    })
      .then(resp => {
        if (resp.ok) {
          return resp.json();
        } else {
          throw new Error('Ошибка при получении данных');
        }
      })
      .then(data => {
        if (by === 'label') {
          setArtLabNames(data.label_names);
          setArtLabInfo('')
          setArtLabAddInfo('')
        } else {
          setArtLabNames(data.artist_names);
          setArtLabInfo('')
          setArtLabAddInfo('')
        }
      })
  }

  async function downloadReport(token, report) {
    const url = download_report(report);
    const params = new URLSearchParams();
    if (isTelegram) {
      params.append('source', 'telegram');
      params.append('chat_id', telegramChatId);
    }

    fetch(`${mainlink}${url}?${params.toString()}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/octet-stream',
      },
    })
      .then(response => {
        if (isTelegram) {
          return response.json();
        }
        return response.blob();
      })
      .then(data => {
        if (!isTelegram) {
          const url = window.URL.createObjectURL(data);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', report);
          document.body.appendChild(link);
          link.click();
          link.remove();
        } else {
          console.log('File sent to Telegram:', data.detail);
          // Здесь можно добавить логику для отображения сообщения пользователю
          // о том, что файл был отправлен в Telegram
        }
      })
      .catch(error => console.error('Error downloading the report:', error));
  }

  function deleteQuarter(filename, yearData, quarter) {
    const url = 'https://gm-stats.ru/api/delete_quarter'
    fetch(url, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        year: yearData,
        quarter: quarter,
        filename: filename
      })
    })
      .then(response => response.json())
      .then(data => {
        fetchAndProcessInfoQuarter(token)
      })
      .catch(error => '');
  }

  function deleteReport(report) {
    const url = 'https://gm-stats.ru/api/delete_reports';
    fetch(url, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ file_names: [report] })
    })
      .then(response => response.json())
      .then(data => {
        fetchAndProcessUserReports(token)
      })
      .catch(error => 'da');
  }

  const [percLoad, setPercLoad] = useState(0)
  const [mainErr, setMainErr] = useState('')
  const [err, setErr] = useState('')
  const [sender, setSender] = useState(false)

  return (
    <>
      {isUploadQuarter &&
        <div className="uploadQuarter">
          <div className="uploadInputs">
            <span
              onClick={() => { setIsUploadQuarter(false) }}
              className='cross'>×</span>
            <h3>Загрузка квартала</h3>
            <div style={err ? { border: 'solid 1px #e24733', padding: '5px 10px' } : {}} className="err">{err}</div>
            <label style={{ textAlign: 'left' }}>Введите год:</label>
            <input
              id="yearpicker"
              className="basic-input"
              placeholder="2024"
              type="text"
              value={year}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <label style={{ marginTop: '15px', textAlign: 'left' }}>Выберите квартал:</label>

            <div className="selector-quarter">
              <span
                className={`btn ${selectedButton === 1 ? 'selectedbtn' : ''}`}
                onClick={() => setSelectedButton(1)}
              >
                1
              </span>
              <span
                className={`btn ${selectedButton === 2 ? 'selectedbtn' : ''}`}
                onClick={() => setSelectedButton(2)}
              >
                2
              </span>
              <span
                className={`btn ${selectedButton === 3 ? 'selectedbtn' : ''}`}
                onClick={() => setSelectedButton(3)}
              >
                3
              </span>
              <span
                className={`btn ${selectedButton === 4 ? 'selectedbtn' : ''}`}
                onClick={() => setSelectedButton(4)}
              >
                4
              </span>
            </div>

            <input
              className="basic-input"
              type="file"
              ref={fileInputRef}
              accept={'.xlsx'}
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
            <div
              className="btn dashed-btn"
              style={{ padding: '5px 10px' }}
              onClick={handleDivClick}
            >
              {fileNameText}
            </div>
            {
              sender ?
                <SpinnerCircularSplit
                  size='40'
                  color="#158b29"
                  secondaryColor="#158b295d"
                  style={{ margin: '0 auto' }}
                />
                :
                <div
                  className="btn"
                  style={{ padding: '5px 10px' }}
                  onClick={handleClickUpload}>Отправить</div>

            }
          </div>
        </div>
      }

      <div className="workspace">
        <div className="left-side">
          {isLeftSideActive && <div className="placeholder"></div>}
          {selectedFile &&
            <>
              <label className="labelBlock">
                Год: {selectedYear},
                Квартал: {selectedQuarter}
              </label>
              <div className="exelBlockbtn addictEB addictstyleEB">
                <img className="addFileImg" src="/table-file-svgrepo-com.svg" alt="" />
                <label className="textAddFile"> {selectedFile} </label>
                <span
                  className="cross-sec"
                  onClick={() => { handleDeleteSelectedSheet() }}
                >✚</span>
              </div>
            </>
          }
          <div style={
            mainErr ?
              { border: 'solid 1px #e24733', padding: '5px 10px' }
              :
              {}
          }
            className="err">{mainErr}</div>
          <div className="selector">
            Выберите формат:
          </div>
          <div className="selector-quarter">
            <span
              className={`btn ${selectedType === 1 ? 'selectedbtn' : ''}`}
              onClick={() => {
                if ((percLoad === 100) || (percLoad === 0)) {
                  setSelectedType(1);
                  get_names(token, selectedFile, 'artist');
                  fetchTrackNames(token, selectedFile);
                } else {
                  setMainErr('Файл еще загружается');
                }
              }}
            >
              По артисту
            </span>
            <span
              className={`btn ${selectedType === 2 ? 'selectedbtn' : ''}`}
              onClick={() => {
                if ((percLoad === 100) || (percLoad === 0)) {
                  setSelectedType(2);
                  get_names(token, selectedFile, 'label');
                } else {
                  setMainErr('Файл еще загружается');
                }
              }}
            >
              По лейблу
            </span>
            <span
              className={`btn ${selectedType === 3 ? 'selectedbtn' : ''}`}
              onClick={() => {
                if ((percLoad === 100) || (percLoad === 0)) {
                  setSelectedType(3);
                  get_names(token, selectedFile, 'all');
                } else {
                  setMainErr('Файл еще загружается');
                }
              }}
            >
              По всем
            </span>
          </div>
          <div className="selected">
            <SelectedChild />
          </div>
        </div>
        <div className="right-side">
          <div
            style={{
              marginBottom: '10px',
              height: '30px',
            }}
            className="create-new-upload btn accept-btn white-btn"
            onClick={() => { setIsUploadQuarter(true) }}>
            Загрузить квартал
          </div>
          {((percLoad > 0) && (percLoad !== 100)) ?
            <>
              <label style={{
                opacity: '40%',
                fontWeight: '200',
                margin: '0 auto',
              }}>Подождите немного, файл загружается</label>
              <label style={{
                fontSize: '15px',
                opacity: '40%',
                fontWeight: '500',
                margin: '0 auto',
                marginBottom: "10px",
                lineHeight: '100%',
              }}>Это займет не больше 1 минуты</label>
              <Line percent={percLoad} strokeWidth={2} strokeColor="#33e23c" trailWidth={2} trailColor="#42e23328" />
            </>
            :
            <>
              {
                (lastUploads.length > 0)
                  ? <label className="rslabel">Выбрать из загруженных:</label>
                  : ''
              }
              <div className="tree-main">
                {lastUploads.map(yearData => (
                  <TreeNode key={yearData.year} label={`Год: ${yearData.year}`}>
                    {yearData.quarters.map(quarter => (
                      <TreeNode key={quarter.quarter_number} label={`Квартал: ${quarter.quarter_number}`}>
                        {quarter.filenames.map(filename => (
                          <div
                            className="basic-row-block exelBlockbtn"
                            key={filename}
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              justifyContent: 'start',
                              margin: '5px 10px',
                              marginLeft: '20px',
                            }}>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'start',
                              }}
                              className="" onClick={() => handleSetSelected(filename, yearData, quarter)} >
                              <img className="addFileImg" src="/file-add-svgrepo-com.svg" alt="" />
                              <label className="textAddFile"> {filename} </label>
                            </div>
                            <span
                              onClick={() => deleteQuarter(filename, yearData.year, quarter.quarter_number)}
                              className="cross-alt deleteReport delQu">×</span>
                          </div>
                        ))}
                      </TreeNode>
                    ))}
                  </TreeNode>
                ))}
              </div>
            </>}
          <div className="lastReportsMain">
            {
              (userReports.length > 0)
                ? <label className="lR-label"> Скачать прошлый отчет: </label>
                : ''
            }

            {
              percentLoaderVisible
                ?
                <>
                  <label style={{
                    fontSize: '15px',
                    opacity: '40%',
                    fontWeight: '500',
                    marginBottom: "10px",
                    lineHeight: '100%',
                  }}>Это займет не больше 2 минут</label>
                  <Line
                    percent={count}
                    strokeWidth={2.5}
                    trailWidth={2.5}
                    strokeLinecap='butt'
                    strokeColor="#28aa3e"
                    trailColor="rgba(40, 170, 62, 0.3)" />
                </>
                :
                ''
            }
            <div className="lastReports">
              {userReports.map((report, index) => (
                <div
                  title={report}
                  key={index}
                  className={`reportBlock ${report.includes('zip') ? 'zipReportBlock' : ''}`}>
                  <img
                    className="addFileImg"
                    src={report.includes('zip') ? '/zip-file-svgrepo-com.svg' : '/table-file-svgrepo-com.svg'}
                    alt="" />
                  <div
                    onClick={() => { downloadReport(token, report) }}
                    className="reportLabel">
                    {report}
                  </div>
                  <span title="Удалить отчет" className="cross-alt deleteReport" onClick={() => deleteReport(report)}>×</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Workspace;

const TreeNode = ({ label, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div
        className={`TreeNode ${isOpen ? 'TreeNode_active' : ''}`}
        onClick={toggleOpen}
        style={{ cursor: 'pointer', fontWeight: 'bold' }}>
        <label className="label-treenode">{label}</label>
        <img
          src="/angle-right-svgrepo-com.svg"
          style={isOpen ? { transform: 'rotate(90deg)' } : { transform: 'rotate(0deg)' }}
          className="ugolochek"
          alt=""
        />
      </div>
      {isOpen && (
        <div style={{ cursor: 'pointer', marginLeft: '20px' }}>
          {children}
        </div>
      )}
    </>
  );
};