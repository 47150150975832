import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function Login({ setIsAuth }) {
  const [err, setErr] = useState('')
  const [user, setUser] = useState({
    username: "",
    password: "",
  });

  const navigate = useNavigate();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErr('')

    const data = {
      username: user.username,
      password: user.password,
    };

    const link = "https://gm-stats.ru/api/login";

    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };

    try {
      const response = await fetch(link, options);
      if (response.ok) {
        const result = await response.json();
        const token = result["access_token"];
        localStorage.setItem("token", token);
        setIsAuth(true)
        navigate("/home");
      } else {
        const result = await response.json();
        setErr(result.detail)
        var e = 'e'
      }
    } catch {
      setErr('Неверный логин или пароль')
    }
  };

  return (
    <form className="basic-box" onSubmit={handleSubmit}>
      <div style={err ? { border: 'solid 1px #e24733', padding: '5px 10px' } : {}} className="err">{err}</div>
      <input
        className="basic-input"
        name="username"
        type="text"
        value={user.username}
        onChange={handleChange}
        placeholder="Логин"
      />
      <input
        className="basic-input"
        name="password"
        type="password"
        value={user.password}
        onChange={handleChange}
        placeholder="Пароль"
      />
      <button className="btn alt-btn" type="submit">
        Войти
      </button>
    </form>
  );
}

export default Login;
