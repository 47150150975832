import React, { useState, useEffect } from "react";
import LogoPic from "../../Log-Reg/Group 9.png";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import alert from '../elems/news_icons/alert.svg'
import news from '../elems/news_icons/news.svg'
import notification from '../elems/news_icons/notification.svg'

function Header() {
  Modal.setAppElement("#root");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  let navigate = useNavigate();
  const logout = () => {
    localStorage.clear();
    navigate("/login");
    return "Вышел";
  };

  const data = localStorage.getItem("token");
  const [inviteLinkLabel, setInviteLinkLabel] = useState(
    "Пригласительная ссылка"
  );

  const handleSubmit1 = async (event) => {
    event.preventDefault()

    const formData = new FormData(event.target);
    const title = formData.get("title");
    const text = formData.get("text");
    const file = document.getElementById('filePickerReport').files[0]

    const token = localStorage.getItem("token");
    const requestBody = new FormData();
    requestBody.append("title", title);
    requestBody.append("text", text);
    if (file) {
      requestBody.append("file", file);
    }


    try {
      const response = await fetch("https://gm-stats.ru/api/bug_report", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: requestBody,
      });

      if (response.ok) {
        console.log("Заявка успешно отправлена");
      } else {
        console.error("Ошибка при отправке заявки");
      }
    } catch (error) {
      console.error("Ошибка при отправке заявки:", error);
    }
    event.target.reset();
  };
  const [userName, setUserName] = useState('Профиль')
  useEffect(() => {

    async function getName() {
      const link = 'https://gm-stats.ru/api/get_username'
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };

      try {
        const response = await fetch(link, options);
        if (response.ok) {
          const result = await response.json();
          setUserName(result)
        } else {
          navigate('/login')
          localStorage.clear();
          throw new Error("Ошибка при выполнении запроса");
        }
      } catch (error) {
        console.error("Ошибка:", error);
      }
    }
    getName()

  }, [])

  const [err, setErr] = useState('')
  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(data);
    const link = "/api/jwt";
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + data, // Исправлено здесь
      },
    };

    try {
      const response = await fetch(link, options);
      if (response.ok) {
        const result = await response.json();
        console.log(result);
      } else {
        throw new Error("Ошибка при выполнении запроса");
      }
    } catch (error) {
      console.error("Ошибка:", error);
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => { })
      .catch((error) => {
        console.error("Ошибка при копировании текста в буфер обмена:", error);
      });
  };
  const token = localStorage.getItem('token')
  const inviteLink = async (event) => {
    event.preventDefault();
    const link = "https://gm-stats.ru/api/invite_link";
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    try {
      const response = await fetch(link, options);
      if (response.ok) {
        const result = await response.json();
        copyToClipboard(`https://gm-stats.ru/signup?invite_link=${result["invite_link"]}`);
        setInviteLinkLabel("Пригласительная ссылка скопирована");
        setTimeout(() => {
          setInviteLinkLabel("Скопировать пригласительную ссылку");
        }, 3000);
      } else {
        throw new Error("Ошибка при выполнении запроса");
      }
    } catch (error) {
      console.error("Ошибка:", error);
    }
  };
  const [newPass, setNewPass] = useState('')
  const [newDubliPass, setNewDubliPass] = useState('')
  const [isValidPass, setIsValidPass] = useState(true)
  const [isModalProfileOpen, setIsModalProfileOpen] = useState(false)
  const closeProfileModal = () => setIsModalProfileOpen(false);
  const openProfileModal = () => setIsModalProfileOpen(true);
  const sendReqChangePass = async (event) => {
    event.preventDefault()
    const link = "https://gm-stats.ru/api/change_password";

    if (newPass !== newDubliPass) {
      setErr('Пароли не совпадают')
      return
    }
    if (newPass.length < 8) {
      setErr('Длина пароля должна быть не менее 8 символов')
      return
    }


    var oldPassword = event.target[0].value
    var newPassword = event.target[1].value

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        old_password: oldPassword,
        new_password: newPassword
      })
    };

    try {
      const response = await fetch(link, options);
      if (response.ok) {
        const result = await response.json();
        console.log(result);
        setErr(result.msg)
      } else {
        const result = await response.json();
        setErr(result.detail)
        throw new Error("Ошибка при выполнении запроса");
      }
    } catch (error) {
      console.error("Ошибка:", error);
    }
  }

  useEffect(() => {
    getNews()

  }, [])


  const [myNewsIcon, setMyNewsIcon] = useState('')
  const [newsTitle, setNewsTitle] = useState('')
  const [newsDescription, setNewsDescription] = useState('')
  const [newsSett, setNewsSett] = useState(true)

  function getNews() {
    const url = 'https://gm-stats.ru/api/news';
    fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(data => {

        if (data.icon === 'alert') {
          setMyNewsIcon(alert)
        } else if (data.icon === 'notification') {
          setMyNewsIcon(notification)
        } else if (data.icon === 'news') {
          setMyNewsIcon(news)
        } else {
          setMyNewsIcon(news)
        }
        setNewsTitle(data.title)
        setNewsDescription(data.description)
      })
      .catch(error => 'da');
  }


  return (
    <div className="double-header">
      <div className="header basic-row-block">
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          className="modalWin2"
          overlayClassName="modalWinOverlay2"
        >
          <div className="basic-box ext">
            <div
              onClick={closeModal}
              style={{
                position: "absolute",
                right: "15px",
                top: "10px",
                cursor: "pointer",
              }}
            >
              ✖
            </div>
            <h2 style={{ margin: "5px" }}> Оставить заявку </h2>
            <form
              id="reportForm"
              className="basic-box reportFormCL"
              onSubmit={handleSubmit1}
            >
              <input
                placeholder="Тема заявки"
                className="basic-input "
                type="text"
                name="title"
              />
              <textarea
                placeholder="Изложите суть заявки"
                className="basic-input noAnim"
                type="text"
                name="text"
              />
              <input
                type="file"
                accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                id="filePickerReport"
                className="btn dashed-btn"
              />
              <input type="submit" className="btn" value="Отправить" />
            </form>
          </div>
        </Modal>
        <Modal
          isOpen={isModalProfileOpen}
          onRequestClose={closeProfileModal}
          className="modalWin2"
          overlayClassName="modalWinOverlay2"
        >
          <div className="basic-box ext">
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              textAlign: 'left',
              width: '490px',
              margin: '0 auto'
            }}>
              <h2 style={{ fontWeight: '300' }}> Ваш логин: </h2>
              <h2 style={{ marginLeft: '10px' }}>  <code> {userName} </code> </h2>
            </div>
            <div
              onClick={closeProfileModal}
              style={{
                position: "absolute",
                right: "15px",
                top: "10px",
                cursor: "pointer",
              }}
            >
              ✖
            </div>
            <h2 style={{
              textAlign: 'left',
              width: '490px',
              margin: '0 auto'
            }}
            > Сменить пароль </h2>
            <div style={err ? { border: 'solid 1px #e24733', padding: '5px 10px' } : {}} className="err">{err}</div>
            <form
              id="reportForm"
              className="basic-box reportFormCL"
              onSubmit={sendReqChangePass}
            >
              <input
                placeholder="Старый пароль"
                className="basic-input "
                type="text"
                name="oldPassWord"
              />
              <input
                placeholder="Новый пароль"
                className="basic-input "
                type="text"
                name="oldPassWord"
                onChange={(e) => setNewPass(e.target.value)}
              />
              <input
                placeholder="Повторите новый пароль"
                className="basic-input "
                type="text"
                name="newPassWord"
                onChange={(e) => setNewDubliPass(e.target.value)}
              />
              <input type="submit" className="btn" value="Отправить" />
            </form>
          </div>
        </Modal>
        <div>
          <img className="logo-h" src={LogoPic} alt="" />
        </div>
        <div className="rside basic-row-block">
          <div
            onClick={inviteLink}
            className="menu_item btn dashed-btn inviteLink"
          >
            <svg
              className="svgs support"
              width="24"
              height="24"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.5 7.45a1.09 1.09 0 0 0-.05-.23v-.07a.9.9 0 0 0-.16-.23l-5-5a.9.9 0 0 0-.23-.16.27.27 0 0 0-.08 0 .73.73 0 0 0-.27-.1H8.33a2.5 2.5 0 0 0-2.5 2.5V5H5a2.5 2.5 0 0 0-2.5 2.5v8.33a2.5 2.5 0 0 0 2.5 2.5h6.67a2.5 2.5 0 0 0 2.5-2.5V15H15a2.5 2.5 0 0 0 2.5-2.5V7.45Zm-5-2.94 2.16 2.16h-1.33a.83.83 0 0 1-.83-.84V4.51Zm0 11.32a.83.83 0 0 1-.83.84H5a.83.83 0 0 1-.83-.84V7.5A.83.83 0 0 1 5 6.67h.83v5.83a2.5 2.5 0 0 0 2.5 2.5h4.17v.83Zm3.33-3.33a.83.83 0 0 1-.83.83H8.33a.83.83 0 0 1-.83-.83V4.17a.83.83 0 0 1 .83-.84h2.5v2.5a2.5 2.5 0 0 0 2.5 2.5h2.5v4.17Z"
                fill="currentColor"
              ></path>
            </svg>

            <label>{inviteLinkLabel}</label>
          </div>

          <div
            onClick={openModal}
            className="menu_item btn white-btn supportRequest"
          >
            <svg
              className="svgs support"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 2A10 10 0 0 0 2 12v7a1 1 0 0 0 1 1h3a3 3 0 0 0 3-3v-2a3 3 0 0 0-3-3H4a8 8 0 1 1 16 0h-2a3 3 0 0 0-3 3v2a3 3 0 0 0 3 3h3a1 1 0 0 0 1-1v-7A10 10 0 0 0 12 2ZM6 14a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H4v-4h2Zm14 4h-2a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1h2v4Z"
                fill="currentColor"
              ></path>
            </svg>
            <label>Оставить заявку</label>
          </div>

          <div
            onClick={openProfileModal}
            className="menu_item btn dashed-btn-alt">
            <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="25px" height="25px" viewBox="0 0 32 32"><path d="M16 15.503A5.041 5.041 0 1 0 16 5.42a5.041 5.041 0 0 0 0 10.083zm0 2.215c-6.703 0-11 3.699-11 5.5v3.363h22v-3.363c0-2.178-4.068-5.5-11-5.5z" /></svg>
            <label>{userName}</label>
          </div>

          <div onClick={logout} className="menu_item btn LogOut">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.5 15.1a1 1 0 0 1 1.34.45 8 8 0 0 0 10.9 3.53A8 8 0 0 0 19 8.11 8 8 0 0 0 12 4a7.93 7.93 0 0 0-7.16 4.45 1 1 0 0 1-1.8-.9 10 10 0 0 1 16.8-1.76 10 10 0 0 1-5.56 15.95 10 10 0 0 1-11.24-5.29 1 1 0 0 1 .46-1.35ZM3 11h9.6l-2.3-2.29a1 1 0 0 1 1.09-1.64 1 1 0 0 1 .32.22l4 4a1 1 0 0 1 .21.33 1 1 0 0 1 0 .76 1 1 0 0 1-.2.33l-4 4a1 1 0 0 1-1.65-.33 1 1 0 0 1 .22-1.09L12.6 13H3a1 1 0 0 1 0-2Z"
                fill="currentColor"
              ></path>
            </svg>
            <label>Выйти</label>
          </div>
        </div>

      </div>
      {(myNewsIcon && newsTitle && newsDescription && newsSett)
        ?
        <div className="suuper-left-side">
          <div className="newsBlock">
            <span className="cross-sec abs_cross" onClick={() => setNewsSett(false)}>✚</span>
            <img className="iconNews" src={`${notification}`} alt="" />
            <div className="news_text">
              <h3 className="newsTitle">{newsTitle}</h3>
              <p className="newsText">{newsDescription}</p>
            </div>
          </div>
        </div>
        : ''}

    </div>
  );
}

export default Header;
