import React, { useState, useEffect } from 'react';
import LogoPic from './Group 9.png';
import { useNavigate, useLocation } from 'react-router-dom';

function LRHeader() {
    const navigate = useNavigate();
    const location = useLocation(); // Используем useLocation для получения текущего пути

    // Состояние для отслеживания текущего активного пути
    const [activePath, setActivePath] = useState(location.pathname);

    useEffect(() => {
        // Обновляем состояние при изменении пути
        setActivePath(location.pathname);
    }, [location]);

    // Функция для навигации и обновления активного пути
    const handleNavigate = (path) => {
        navigate(path);
        setActivePath(path);
    }

    return (
        <div>
            <div><img className='logo' src={LogoPic} alt='GORDEEV STATS' /></div>
            <div className='centered-row-block'>
                <button
                    className={`authreg ${activePath === '/login' || activePath === '/' ? 'active-authreg' : ''}`}
                    onClick={() => handleNavigate('/login')}
                >
                    АВТОРИЗАЦИЯ
                </button>
                <button
                    className={`authreg ${activePath === '/signup' ? 'active-authreg' : ''}`}
                    onClick={() => handleNavigate('/signup')}
                >
                    РЕГИСТРАЦИЯ
                </button>
            </div>
        </div>
    );
}

export default LRHeader;