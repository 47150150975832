import React, { useEffect, useState } from "react";
import LogoPic from "./Log-Reg/Group 9.png";
import { useNavigate } from "react-router-dom";

function NotFoundPage() {
  const [color, setColor] = useState("yellow");
  const [status, setStatus] = useState("Загрузка");
  const size = 13;

  const navigate = useNavigate()
  const token = localStorage.getItem("token");

  useEffect(() => {
    fetch("/api", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setStatus("Онлайн");
        setColor("#3bfd5c");
        console.log(data);
      })
      .catch((error) => {
        setColor("#e24733");
        setStatus("Оффлайн");
        console.log(error);
      });
  }, []);

  return (
    <>
      <div className="basic-box noBordShad errPg">
        <img
          className="logo errPglogo"
          style={{ marginBottom: "15px" }}
          src={LogoPic}
          alt=""
        />
        <div className="centered-row-block shr">
          4<p>0</p>4
        </div>
        <div className="" style={{ padding: "15px 25px" }}>
          <div style={{ fontWeight: "500", fontSize: "25px" }}>
            Статус сервера:{" "}
          </div>
          <div className="centered-row-block centered errPgBord">
            <div>{status}</div>
            <div
              className="centered-row-block centered"
              style={{ marginLeft: "5px", minWidth: "10px" }}
            >
              <div
                style={{
                  width: `${size}px`,
                  height: `${size}px`,
                  borderRadius: "50%",
                  background: `${color}`,
                  margin: "0 auto",
                }}
              >
                <div
                  className="animationServerStatus"
                  style={{
                    width: `${size}px`,
                    height: `${size}px`,
                    borderRadius: "50%",
                    background: `${color}`,
                    margin: "0 auto",
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default NotFoundPage;
