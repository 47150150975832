

export const mainlink = 'https://gm-stats.ru/api';
export const status = '/';
export const login = '/login';
export const get_username = '/get_username';
export const register = '/register';
export const register_admin = '/register_admin';
export const upload = '/upload';
export const artist_report = '/artist_report';
export const label_report = '/label_report';
export const all_report = '/all_report';
export const info_quarter = '/info_quarter';
export const user_reports = '/user_reports';
export const download_report = (fileName) => `/download_report/${fileName}`;
export const get_label_names = (fileName) => `/get_label_names/${fileName}`;
export const get_artist_names = (fileName) => `/get_artist_names/${fileName}`;
export const get_track_names = (fileName) => `/get_track_names/${fileName}`;
export const delete_reports = '/delete_reports';

// Пример использования
// postRequest(url, body)
//   .then(data => console.log('Response:', data))
//   .catch(error => console.error('Error:', error));
export async function postFormDataRequest(url, formData, token = null) {
    try {
        const headers = {};
        if (token) {
            headers['Authorization'] = `Bearer ${token}`;
        }

        const response = await fetch(mainlink + url, {
            method: 'POST',
            headers,
            body: formData
        });

        // if (!response.ok) {
        //     throw new Error('Ошибка HTTP: ' + response.status);
        // }
        return await response.json();
    } catch (error) {
        console.error('Ошибка при загрузке файла:', error);
        return null;
    }
}

export async function postRequest(url, body, token = null) {
    try {
        const headers = {
            'Content-Type': 'application/json'
        };
        if (token) {
            headers['Authorization'] = `Bearer ${token}`;
        }

        const response = await fetch(mainlink + url, {
            method: 'POST',
            headers,
            body: JSON.stringify(body)
        });

        if (!response.ok) {
            throw new Error('Ошибка HTTP: ' + response.status);
        }

        return await response.json();
    } catch (error) {
        return error;
    }
}

export async function deleteRequest(url, body = null, token = null) {
    try {
        const headers = {
            'Content-Type': 'application/json'
        };
        if (token) {
            headers['Authorization'] = `Bearer ${token}`;
        }

        const response = await fetch(mainlink + url, {
            method: 'DELETE',
            headers,
            body: body ? JSON.stringify(body) : null
        });

        if (!response.ok) {
            throw new Error('Ошибка HTTP: ' + response.status);
        }

        return await response.json();
    } catch (error) {
        return error;
    }
}

export async function getRequest(url, token = null) {
    try {
        const headers = {
            'Content-Type': 'application/json'
        };
        if (token) {
            headers['Authorization'] = `Bearer ${token}`;
        }
        const response = await fetch(mainlink + url, {
            method: 'GET',
            headers,
        });

        if (!response.ok) {
            throw new Error('Ошибка HTTP: ' + response.status);
        }

        return await response.json();
    } catch (error) {
        return error;
    }
}
