// Signup.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Signup() {
    const [err, setErr] = useState('')
    const navigate = useNavigate();
    const [user, setUser] = useState({
        username: '',
        invite_link: '',
        password: '',
    });

    useEffect(() => {
        var token = localStorage.getItem('token')
        if (token) {
          navigate('/home')
        }
      }, [])

    const [invite_link, setInviteLink] = useState('');
    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        if (urlParams.get('invite_link') !== null) {
            var inviteLinkValue = urlParams.get('invite_link');
            localStorage.setItem('inviteLinkValue', inviteLinkValue)
        } else if (localStorage.getItem("inviteLinkValue") !== null) {
            var inviteLinkValue = localStorage.getItem("inviteLinkValue");
        }
        // Установка извлеченного значения в состояние user
        if (inviteLinkValue) {
            setUser(prevState => ({
                ...prevState,
                invite_link: inviteLinkValue,
            }));
        }
    }, []);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setUser(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };


    const data = {
        user_name: user.username,
        invite_link: user.invite_link,
        password: user.password
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!(user.username || user.invite_link || user.password)) {
            return
        }

        if ((user.username).length < 5) {
            setErr('Минимальная длина логина 5 символов')
            return
        }

        if ((user.password).length < 8) {
            setErr('Минимальная длина пароля 8 символов')
            return
        }

        const link = 'https://gm-stats.ru/api/register';
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        };
        const response = await fetch(link, options);

        if (response.ok) {
            const result = await response.json();
            const token = result['token']
            localStorage.setItem('token', token)
            navigate('/home');
        } else {
            const result = await response.json();
            setErr(result.detail)
            console.log(result)
        }

    }

    return (
        <>
            {/* <LRHeader /> */}
            <form className='basic-box' onSubmit={handleSubmit}>
                <div style={err ? { border: 'solid 1px #e24733', padding: '5px 10px' } : {}} className="err">{err}</div>
                <input
                    className='basic-input'
                    name="username"
                    type="text"
                    value={user.username}
                    onChange={handleChange}
                    placeholder="Логин"
                />
                <input
                    className='basic-input'
                    name="invite_link"
                    type="text"
                    value={user.invite_link}
                    onChange={handleChange}
                    placeholder="Пригласительная ссылка"
                />
                <input
                    className='basic-input'
                    name="password"
                    type="password"
                    value={user.password}
                    onChange={handleChange}
                    placeholder="Пароль"
                />
                <button className='btn alt-btn' type="submit">Зарегистрироваться</button>
            </form>
        </>
    );
}

export default Signup;