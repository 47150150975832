/* eslint-disable */
import React, { useState } from "react";
import Header from "./Header/header.js";
import SvgFile2 from "./elems/error-svgrepo-com.svg";
import { SpinnerDotted } from "spinners-react";
import * as API from '../api';
import Workspace from './WorkSpace/workspace.js'

function Main() {

  const [isLoader, setIsLoader] = useState(false);

  return (
    <>
      {isLoader && (
        <div className="spinnerBlock">
          <SpinnerDotted
            size="100px"
            style={{ color: "rgb(255, 255, 255)" }}
            className="spinner"
            сolor="rgb(255, 255, 255)"
            speed="23"
          />
        </div>
      )}
      <Header />
      <Workspace />
    </>
  );
}

export default Main;
